<template>
	<button @click="btnClick(button)">
			<slot></slot>
			<i v-if="!('image1' in button)" class="fal fa-volume-up"></i>
			<span v-else v-html="(redText(button.text))"></span>
	</button>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
const { button } = defineProps(['button']);
const emit = defineEmits(['btnClick']);
const store = useStore();

const redText = (text) => {
	let txt = text;
	if(text.indexOf('1') > -1 && text.indexOf('2') > -1) {
		return txt.replace(/1/g, '<span style="color:red;">').replace(/2/g, '</span>');
	}
	return text;
};

const btnClick = btn => {
	store.dispatch('program/playSnd', btn);
	emit('btnClick', btn);
}
</script>

<style lang="scss" scoped>
	button {
		margin: .1rem;
		min-width: 59px;
		width: auto;
	}
</style>