<template>
	<div class="progress-page">
		<main>
			<h1 class="text-center">Your Progress</h1>
			<div  v-for="(val, key) in Object.getOwnPropertyDescriptors(progress)" :key="key">
				<div class="progress-type">
					<a :class="{'progress-toggle': true, active: toggleTypes[key].show }" 
						 @click.prevent="toggleTypes[key].show = !toggleTypes[key].show">
						<div>
							<h2>{{ $filters.capitalize(key) }}</h2>
							<small>{{ progStats.types[key].cnt }} of {{ progStats.types[key].max }} ({{ progStats.types[key].percent.toFixed(0) + '%' }})</small>
							<progress-bar :height="'19px'" :width="progStats.types[key].percent + '%'"></progress-bar>
						</div>
						<i class="fal fa-angle-right"></i>
					</a>
				</div>
				<div class="list-container">
					<Transition name="fade-slide">
					<ul v-if="toggleTypes[key].show">
						<li v-for="data, cat in val.value" :key="cat">
							<template v-if="cat != $filters.capitalize(key)">
								<a :class="{'progress-toggle': true, active: toggleCats[cat].show }"
									@click.prevent="toggleCats[cat].show = !toggleCats[cat].show">
									<div>
										<h3>{{ cat }}</h3>
										<small>{{ progStats.cats[cat].cnt }} of {{ progStats.cats[cat].max }} ({{ progStats.cats[cat].percent.toFixed(0) + '%' }})</small>
										<progress-bar :height="'10px'" :width="progStats.cats[cat].percent + '%'"></progress-bar>
									</div>
									<i class="fal fa-angle-right"></i>
								</a>
							</template>
							<div class="list-container">
								<Transition name="fade-slide">
								<ul v-if="toggleCats[cat].show || cat == $filters.capitalize(key)">
									<li v-for="row, slug in data" :key="slug">
										<strong>{{ row.title }}</strong><small>{{ row.completed.length }} of {{ row.max }} completed</small>
										<progress-bar :height="'5px'" :width="((row.completed.length / row.max) * 100) + '%'"></progress-bar>
									</li>
								</ul>
								</Transition>
							</div>
						</li>
					</ul>
					</Transition>
				</div>
			</div>
		</main>
	</div>
</template>

<script setup>
// TODO header progress bar to show overall progress bar
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

import ProgressBar from '@/components/ProgressBar';

const store = useStore();
const progress = computed(() => store.getters['program/getProgress']);
const progStats = computed(() => {
	let rtnData = {types: {}, cats: {}};
	for(const [type, cats] of Object.entries(progress.value)) { // loop thru types
		let cnt = 0;
		let max = 0;
		rtnData.types[type] = {cnt, max, percent: 0, show: false};
		for(const [cat, lessons] of Object.entries(cats)) { // loop thru cats

			rtnData.cats[cat] = {cnt, max, percent: 0, show: false};

			for(const[slug, lesson] of Object.entries(lessons)) { // loop thru lessons
				let complete = lesson.completed.length;
				let max = lesson.max;
				rtnData.cats[cat].cnt += complete;
				rtnData.cats[cat].max += max;

				rtnData.types[type].cnt += complete;
				rtnData.types[type].max += max;
			}
		}
	}
	for(const [key, val] of Object.entries(rtnData.cats)) {
		rtnData.cats[key].percent = (val.cnt / val.max) * 100;
	}
	for(const [key, val] of Object.entries(rtnData.types)) {
		rtnData.types[key].percent = (val.cnt / val.max) * 100;
	}
	return rtnData;
});

const toggleCats = reactive(progStats.value.cats);
const toggleTypes = reactive(progStats.value.types);
		
</script>

<style lang="scss" scoped>
	.progress-page {
		background-color: white;

		strong {
			display: inline-block;
		}

		.list-container {
			flex-basis: 100%;
			position: relative;
			overflow: hidden;
		}

		.progress-toggle {
			align-items: center;
			color: $black;
			cursor: pointer;
			display: flex;
			flex-basis: 100%;

			div {
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;

				strong, small {
					flex-shrink: 1;
				}
				.progress-bar {
					flex-basis: 100%;
					margin: .3rem 0 1rem;
				}
			}

			i.fal {
				font-size: 2rem;
				padding: .5rem 0 1rem 1rem;
				transition: all .3s;
				transform-origin:  70% 40%;
			}

			&.active {
				i.fal {
					transform: rotateZ(90deg);
				}
			}
		}

h2, h3 {
	margin: 0;
}
		ul {
			list-style: none;
			padding: 0 1.75rem 0 1rem;

			li {
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: .25rem 0;
				width: 100%;

				strong, small {
					flex-shrink: 1;
				}

				.progress-bar {
					flex-basis: 100%;
					margin: .3rem 0 1rem;
				}
			}

			ul {
			flex-basis: 100%;
			list-style: none;
		}
	}
}
</style>