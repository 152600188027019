<template>
	<div class="profile-page">
		<main>
			<h2>{{ user.first_name }} {{ user.last_name }}'s User Profile</h2>
			<section class="form">
				<form-field v-model="first_name">First Name:</form-field>
				<form-field v-model="last_name">Last Name:</form-field>
				<form-field v-model="native_language">Native Language:</form-field>

				<hr>

				<form-field type="email" v-model="email">Email</form-field>
				<form-field type="password" v-model="password">Current Password</form-field>
				<form-field type="password" v-model="new_password">New Password</form-field>


				<small>* Changing your email or password will require you to enter your current password.</small><br><br>
				<button class="button" type="submit" @click="updateProfile"><mini-load></mini-load> Update Profile</button>
				<msg-box></msg-box>
			</section>
		</main>
	</div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import UserService from '@/services/UserService';
// import UserHeader from '@/components/UserHeader';
import FormField from '@/components/FormField';
import MsgBox from '@/components/MsgBox';

const store = useStore();
const loader = ref(false);
let user = store.getters['auth/getUser'];
const first_name = ref(user.first_name);
const last_name = ref(user.last_name);
const native_language = ref(user.native_language);
const email = ref(user.email);
const password = ref('');
const new_password = ref('');

const updateProfile = async () => {
	loader.value = true;
	try {
		let data = {
			first_name: first_name.value,
			last_name: last_name.value,
			native_language: native_language.value
		};
		if(email.value != user.email) {
			data.email = email;
		}
		if(new_password.value != '') {
			data.new_password = new_password.value;
		}
		let resp = await store.dispatch('auth/updateUser', data);
		store.dispatch('setMsg', { resp });
	} catch (err) {
		store.dispatch('setMsg', { resp: err.response });
	}
	loader.value = false;
};
</script>

<style lang="scss" scoped>
	.profile-page {
		background-color: white;
	}
</style>