<template>
	<div class="progress-bar">
		<Transition>
			<div class="bar" :style="{ height: (height ?? '1px;'), width: dispWidth }"></div>
		</Transition>
	</div>
</template>

<script setup>
import { onMounted, defineProps, ref } from 'vue';

const props = defineProps(['height','width']);
const dispWidth = ref('0');

onMounted(() => {
	setTimeout(() => {
		dispWidth.value = props.width;
	}, 300);
});
</script>

<style lang="scss" scoped>

	.progress-bar {
		background-color: $grey;
		border-radius: 5px;

		.bar {
			background-color: #71C562;
			border-radius: 3px;
			transition: width .75s ease;
		}
	}
</style>