<template>
		<div v-if="page.description">	
			<hr class="top">
			<article>
				<sound-btn v-if="page.description.audio" :button="page.description"></sound-btn> 
				<span v-html="underline(page.description.text)"></span>
			</article>
			<hr class="bottom">
		</div>
</template>

<script setup>
	import { computed } from 'vue';
	import { useStore } from 'vuex';

	const store = useStore();
	const page = computed(() => store.getters['program/getPage']);

	const underline = txt => txt.replace('class=\'underline\'', 'style="text-decoration:underline;"');
</script>

<style lang="scss" scoped>
	article {
		flex-basis: 100%;
		font-size: 1.1rem;
		line-height: 30px;

		& >>> .underline {
			text-decoration: underline;
		}

		button {
			align-items: center;
			border-radius: 50%;
			display: inline-flex;
			height: 30px;
			justify-content: center;
			margin: 0;
			margin-right: 1rem;
			min-width: 0;
			padding: 0;
			width: 30px;
		}
	}
	hr {
		&.top {
			margin: .75rem 0;
			flex-basis: 100%;
		}
		&.bottom {
			margin: 1.25rem auto;
			width: 20%;
		}
	}
</style>