<template>
	<div class="program-map">
		<div>
			<page-title>Program Map</page-title>
			<ul v-if="active">
				<map-tree v-for="item in map" :key="item.id" :node="item" :top="true" :topId="item.id"></map-tree>
			</ul>
			<expire-msg v-else />
		</div>
	</div>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import MapTree from '@/components/MapTree';
import ExpireMsg from '@/components/ExpireMsg';
const store = useStore();
const map = computed(() => store.getters['program/getMap']);
const active = computed(() => store.getters['auth/isActive']);

onMounted(() => {
	if(!map) store.dispatch('setMap');
});
</script>

<style lang="scss" scoped>
.program-map {
	background-color: $white;;
}
ul > li {
	border-left: 0;

	&:before {
		display: none;
	}
}
</style>