import 'es6-promise/auto'

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from '@/router'
import store from '@/store'
import axios from 'axios'

import MiniLoader from '@/components/MiniLoader';
import PageTitle from '@/components/PageTitle.vue';
import SoundBtn from '@/components/SoundBtn.vue';
import Description from '@/components/Description.vue';

import('@/assets/css/index.css');

// set auth header
axios.defaults.withCredentials = true
// axios.defaults.baseURL = 'http://localhost:8080'
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, DELETE, PUT';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'X-Requested-With, Content-Type, Authorization, Origin, Accept';

const app = createApp(App).use(store).use(router)

app.component('mini-load', MiniLoader);
app.component('page-title', PageTitle);
app.component('sound-btn', SoundBtn);
app.component('description', Description);

// app.provide('$store', store);
// app.provide('$router', router);

app.directive('visible', function(el, binding) {
	el.style.visibility = binding.value ? 'visible' : 'hidden';
});

app.config.globalProperties.$filters = {
	capitalize(data) {
		var capitalized = [];
		data.split(' ').forEach(word => {
			capitalized.push(
				word.charAt(0).toUpperCase() +
				word.slice(1).toLowerCase()
			);
		});
		return capitalized.join(' ');
	}
};

app.mount('#app');
