<template>
	<label class="toggle-container">
		<div class="switch-toggle">
				<div class="button-check" id="button-check">
					<input type="checkbox" class="checkbox" :checked="props.checked" @input="handleInput">
					<span class="switch-btn"></span>
					<span class="layer"></span>
				</div>
		</div>
		<span><slot></slot></span>
	</label>
</template>

<script setup>
	import { defineProps, defineEmits, ref } from 'vue';
	const props = defineProps(['checked']);
	const emit = defineEmits(['checked']);
	const handleInput = $event => {
		emit('checked', $event.target.checked);
	}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  outline: 0;
  font-family: 'IBM Plex Sans', sans-serif;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.toggle-container {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
.switch-toggle {
  display: flex;
  height: 100%;
  align-items: center;

	& + span {
		padding: .5rem 0 .5rem 1rem;
	}
}
.switch-btn, .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button-check {
  position: relative;
  width: 60px;
  height: 31px;
  overflow: hidden;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.switch-btn {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #fdd1d1;
  transition: 0.3s ease all;
  z-index: 1;
}
#button-check .switch-btn:before, #button-check .switch-btn:after {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 23px;
  height: 23px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #00921c;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  display: flex;
  align-items: center;
  justify-content: center;
}

#button-check .switch-btn:before {
  content: '\02713';
}

#button-check .switch-btn:after {
  content: '\02715';
}
#button-check .checkbox + .switch-btn:after {
  background-color: #F44336;
  left: auto;
  right: 4px;
}

#button-check .checkbox:checked + .switch-btn:after {
  right: -50px;
}

#button-check .checkbox + .switch-btn:before {
  left: -50px;
}
#button-check .checkbox:checked + .switch-btn:before {
  left: 4px;
}



#button-check .checkbox:checked ~ .layer {
  background-color: #8cf7a0;
}
</style>