<template>
	<aside class="expire-msg">
	Your Subscription has Expired.<hr>Please renew by purchasing a new learning package.
	</aside>
</template>

<script setup>

</script>

<style lang="scss" scoped>
	.expire-msg {
    color: #842029;
		display: block;
    background-color: #f8d7da;
    border-color: #f5c2c7;
		margin: 2rem 1rem;
		padding: 1rem !important;
		width: auto !important;
	}
</style>