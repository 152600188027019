import { createStore } from 'vuex'
import * as auth from '@/store/modules/Auth';
import * as program from '@/store/modules/Program';

// import { doc } from 'prettier';
// import Axios from 'axios'
// import createPersistedState from 'vuex-persistedstate'

export default createStore({
  strict: true,
	state: {
		userMenu: false,
		msg: {
			text: '',
			status: false
		}
	},
	getters: {
		getUserMenu: state => state.userMenu,
		getMsg: state => state.msg,
	},
	mutations: {
		SET_USER_MENU: (state, status) => {
			state.userMenu = status;
		},
		SET_MSG: (state, msg) => {
			state.msg = msg;
		} 
	},
	actions: {
		setUserMenu: ({ commit, dispatch }, status) => {
			commit('SET_USER_MENU', status);
			if(status)
				dispatch('program/showInstructions', false);
		},
		setMsg: ({ commit }, { msg, resp }) => {
			let message = msg ?? {};

			if(resp) {
				if(resp.status == 422) {
					message.text = resp.data.errors[Object.keys(resp.data.errors)[0]][0] ?? 'There was a problem.';
				} else {
					message.text = resp.data.message;
				}
				message.status = resp.status == 200;
			}

			commit('SET_MSG', message);
		},
		resetMsg: ({ commit }) => {
			commit('SET_MSG', { text: '', status: false });
		}
	},
  // plugins: [createPersistedState()],
  modules: {
		auth,
		program
  }
})