import Localbase from 'localbase'
import PageService from '@/services/PageService';

let timeout1, timeout2;
let audio = new Audio();
let audio2 = new Audio();
let db = new Localbase('db');

export const namespaced = true;

export const state = {
	progress: {vowels: {}, consonants: {}, intonation: {}},
	showInstructions: false,
	page: {},
	map: [],
	mapId: 0,
	ipa: {
		vowels: { pg: 0, btn: 0, img: '' },
		consonants: { pg: 0, btn: 0, img: '' },
		intonation: { pg: 0, btn: 0, img: '' }
	},
	image: 'imgs/starting.png',
	hasSound: false,
	isAudioPlaying: false,
	hasRecording: false,
	isRecording: false,
	showLoader: false,
	loadingProgress: 0
};

export const getters = {
	getIpa: state => {
		let type = state.page.type;
		if(Object.keys(state.page).length > 0 && type && state.ipa[type]) {
			return state.ipa[type].pg ?? 0;
		}
		return 0;
	},
	getIpaBtn: state => {
		let type = state.page.type;
		if(Object.keys(state.page).length > 0 && type && state.ipa[type]) {
			return state.ipa[type].btn ?? 0;
		}
		return 0;
	},
	getIpaImg: state => {
		let type = state.page.type;
		if(Object.keys(state.page).length > 0 && type && state.ipa[type]) {
			return state.ipa[type].img ?? 0;
		}
	},
	getProgress: state => state.progress,
	getShowLoader: state => state.showLoader,
	getLoadingProgress: state => {
		return state.loadingProgress;
	},
	getMap: state => state.map,
	getMapId: state => state.mapId,
	getShowInstructions: state => state.showInstructions,
	getPage: state => {
		return state.page;
	},
	getImage: state => {
		return state.image;
	},
	hasSound: state => {
		return state.hasSound;
	},
	getRecordingStatus: state => {
		return state.isRecording;
	},
	hasRecording: state => {
		return state.hasRecording;
	}
};

export const mutations = {
	SET_PROGRESS_PAGE: (state, page) => {
		let progress = state.progress;
		progress[page.type][page.parent_title][page.slug].completed.push(page.id);

		state.progress = progress;
	},
	SET_PROGRESS: (state, progress) => {
		state.progress = progress;
	},
	SET_SHOW_LOADER: (state, status) => state.showLoader = status,
	SET_LOADING_PROGRESS: (state, progress) => {
		state.loadingProgress = progress;
	},
	SET_IPA: (state, id) => {
		let type = state.page.type;
		state.ipa[type].pg = id;
	},
	SET_IPA_BTN: (state, id) => {
		let pg = state.page;
		let type = pg.type;
		state.ipa[type] = { pg: pg.id, btn: id, img: '' };
	},
	SET_IPA_IMG: (state, img) => {
		let pg = state.page;
		let type = pg.type;
		state.ipa[type].img = img;
	},
	SET_MAP: (state, map) => {
		state.map = map;
	},
	SET_MAP_ID: (state, id) => {
		state.mapId = id;
	},
	SET_SHOW_INSTRUCTIONS: (state, show) => state.showInstructions = show,
	SET_PAGE: (state, page) => {
		audio.pause();
		audio.currentTime = 0;
		state.page = page;
	},
	SET_IMG: (state, img) => {
		state.image = img;
	},
	SET_AUDIO_PLAY: (state, btnObj) => {
		audio.pause();
		audio.currentTime = 0;
		audio2.pause();
		audio2.currentTime = 0;
		state.isAudioPlaying = true;
		if('image1' in btnObj) {
			state.hasSound = true;
			audio.src = btnObj.audio;
			audio.play();
		} else {
			audio2.src = btnObj.audio;
			audio2.play();
		}
	},
	SET_AUDIO_PAUSE: (state) => {
		audio.pause();
		audio.currentTime = 0;
		audio2.pause();
		audio2.currentTime = 0;
		state.isAudioPlaying = false;
	},
	SET_SND_STATUS: (state, status) => {
		state.hasSound = status;
	},
	SET_RECORDING_STATUS: (state, status) => {
		state.isRecording = status;
	},
	SET_HAS_RECORDING: (state, status) => {
		state.hasRecording = status;
	},
};

export const actions = {
	async setLastPage({ commit, rootGetters }, id) {
		let timeout = rootGetters['auth/getLastPgTimeout'];
    clearTimeout(timeout);
    timeout = setTimeout(() => PageService.setLastPage(id), 3000);
		commit('auth/SET_LAST_PAGE_TIMEOUT', timeout, { root: true });
		commit('auth/SET_LAST_PAGE', id, { root: true });
	},
	saveProgress: ({ commit, getters }, page) => {
		let progress = getters.getProgress;
		let row = progress[page.type][page.parent_title][page.slug];
		if(row && row.completed.indexOf(page.id) == -1) {
			commit('SET_PROGRESS_PAGE', page);
			PageService.saveProgress(page.id);
		}
	},
	syncProgress: ({ commit }, { progress }) => {
		commit('SET_PROGRESS', progress);
	},
	showLoader: ({ commit }, status) => {
		commit('SET_SHOW_LOADER', status);
	},
	setLoadingProgress: ({ commit }, progress) => {
		commit('SET_LOADING_PROGRESS', progress);
	},
	setIpa: ({ commit, getters }, id) => {
		commit('SET_IPA', id);
	},
	setIpaBtn: ({ commit }, id) => {
		commit('SET_IPA_BTN', id);
	},
	setIpaImg: ({ commit }, img) => {
		commit('SET_IPA_IMG', img);
	},
	async setMap({ commit, rootGetters }) {
		if(rootGetters['auth/isActive']) {
			let map = await PageService.getMap();
			commit('SET_MAP', map.data);
		}
	},
	setMapId({ commit }, id) {
		commit('SET_MAP_ID', id);
	},
	showInstructions: ({ commit, getters, dispatch }, show) => {
		let curInst = getters.getShowInstructions;
		commit('SET_SHOW_INSTRUCTIONS', show ?? !curInst);
		if(getters.getShowInstructions) {
			dispatch('setUserMenu', false, { root: true });
		}
	},
	async changePage({ commit, dispatch }, id) {
		let newPage;
		if(id == 'home') {
			newPage = await db.collection('pages').doc({ slug: id }).get();
		} else {
			newPage = await db.collection('pages').doc(id.toString()).get();
		}
		if(!newPage) return;
		let childIds = newPage.child_ids;

		if(newPage.template != null && newPage.template != 'Ipa') {
			dispatch('saveProgress', newPage);
		}

		dispatch('setLastPage', newPage.id);

		if(newPage.template != 'imgexplain' && newPage.image) {
			commit('SET_IMG', '/imgs/'+newPage.image);
		}
		// db.config.debug = false;

		// Get Children, Siblings, Prev and Next
		let children = [];

		for(let i=0; i<childIds.length; i++) {
			let id = childIds[i];
			let itm;
			try {
				itm = await db.collection('pages').doc({ id }).get();
			} catch(err) {
				await PageService.syncPage(id);
				itm = await db.collection('pages').doc({ id }).get();
			}
			if(itm) children.push(itm);
		}

		newPage.children = children;
		commit('SET_HAS_RECORDING', false);
		commit('SET_SND_STATUS', false);
		dispatch('pauseSnd');
		commit('SET_PAGE', newPage);
	},
	backPage: ({ getters, dispatch }) => {
		dispatch('changePage', getters.getPage.parent_id);
	},
	prevPage: ({ getters, dispatch }) => {
		dispatch('changePage', getters.getPage.prev_id);
	},
	nextPage: ({ getters, dispatch }) => {
		dispatch('changePage', getters.getPage.next_id);
	},
	selectPage: ({ dispatch }, selectedId) => {
		dispatch('changePage', selectedId);
	},
	changeImg: ({ commit }, img) => {
		commit('SET_IMG', img);
	},
	resetImg: ({ commit, getters }) => {
		let page = getters.getPage;
		let	img = 'imgs/'+page.image;
		commit('SET_IMG', img);
	},
	playLastSnd: () => {
		audio.pause();
		audio.currentTime = 0;
		audio2.pause();
		audio2.currentTime = 0;
		audio.play();
	},
	playSnd: ({ commit, dispatch, getters }, btnObj) => {
		clearTimeout(timeout1);
		clearTimeout(timeout2);
		let img1 = btnObj.image1;
		let pg = getters.getPage;
		switch(pg.slug) {
			case 'place':
				img1 = btnObj.fimage ?? img1;
				break;
			case 'airflow':
				img1 = btnObj.aimage ?? img1;
				break;
			case 'voicing':
				img1 = btnObj.vimage ?? img1;
		}

		if(img1) {
			dispatch('changeImg', img1);
			timeout1 = setTimeout(() => {
				if(btnObj.image2) {
					dispatch('changeImg', btnObj.image2);
					timeout2 = setTimeout(() => {
						dispatch('resetImg');
						clearTimeout(timeout1);
					}, btnObj.time2 * 1000);
				} else {
					dispatch('resetImg');
				}
			}, btnObj.time1 ? (btnObj.time1 * 1000) : 1275);
		}
		commit('SET_AUDIO_PLAY', btnObj);
	},
	pauseSnd: ({ commit }) => {
		commit('SET_AUDIO_PAUSE');
	},
	startRecording: ({ commit }) => {
		commit('SET_HAS_RECORDING', false);
		commit('SET_RECORDING_STATUS', true);
	},
	stopRecording: ({ commit }) => {
		commit('SET_HAS_RECORDING', true);
		commit('SET_RECORDING_STATUS', false);
	},
};