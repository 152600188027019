<template>
	<div v-if="show ?? false">
		<i :style="{ fontSize: size ?? '1em'}" class="fa fa-circle-notch fa-pulse"></i>
	</div>
	<span v-else><slot></slot></span>
</template>

<script>
export default {
	props: ['show', 'size'],
	setup() {
		
	},
}
</script>

<style lang="scss" scoped >
	div {
		text-align: center;
	}
	i, span {
		display: inline-block;
	}
</style>