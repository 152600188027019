<template>
	<li>
		<h3 v-if="top == true">
			<a @click.prevent="topClick(node.id)">{{ node.title }} <i :class="'fal '+ (showList ? 'fa-angle-down' : 'fa-angle-right')"></i></a>
		</h3>
		<strong v-else>
			<a @click.prevent="linkClick(node.id, topId)" v-html="linkText(node)"></a>
		</strong>
		<ul v-if="node.children.length > 0 && showList">
			<map-tree v-for="item in node.children" :key="item.id" :node="item" :top="false" :show="true" :topId="topId"></map-tree>
		</ul>
	</li>
</template>

<script setup>
	import { onMounted, defineProps, computed, ref } from 'vue';
	import { useStore } from 'vuex';
	import { useRouter } from 'vue-router';

	import MapTree from '@/components/MapTree';

	const store = useStore();
	const router = useRouter();
	const { node, top, topId, show } = defineProps(['node','top','topId','show']);
	const showList = ref(show ?? false);
	const userPages = computed(() => store.getters['auth/getUserPgs']);

	const linkClick = (id, topId) => {
		store.dispatch('program/setMapId', topId);
		store.dispatch('program/changePage', id);
		router.push('/');
	}

	const linkText = link => {
		return link.title + ` <small title="Number of pages in lessson">${ link.max > 1 ? '('+link.max+')' : '' }</small>`;
	}

	const topClick = id => {
		let mapId = store.getters['program/getMapId'];
		store.dispatch('program/setMapId', mapId == id ? 0 : id);
		showList.value = !showList.value;
	}

	onMounted(() => {
		if(top == true && store.getters['program/getMapId'] == node.id) {
			showList.value = true;
		}
	})
</script>

<style lang="scss" scoped>
h3 {
	i.fal {
		padding-left: 1rem;
		color: $primary;
	}
}

li {
  --spacing : .5rem;
  --radius  : 10px;
}
	ul {
		list-style: none;
		margin-left  : calc(var(--radius) - var(--spacing));
		padding-left : 0;

		li {
			border-left : 2px solid #ddd;
			display      : block;
			position     : relative;
			padding-left : calc(2 * var(--spacing));
			
			&:before {
				content      : '';
				display      : block;
				position     : absolute;
				top          : calc(var(--spacing) / 2);
				left         : -2px;
				width        : calc(var(--spacing) + 2px);
				height       : calc(var(--spacing) + 1px);
				border       : solid #ddd;
				border-width : 0 0 2px 2px;
			}

			&:last-child{
				border-color : transparent;

				&:before {
					height       : calc(var(--spacing) + 4px);
    			top: calc(var(--spacing) / 2 - 4px);
				}
			}
		}

		a {
			cursor: pointer;
			display: inline-block;
			padding: .25rem 0;
		}
	}
</style>